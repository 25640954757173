<template>
  <el-config-provider :locale="zhCn">
    <router-view/>
  </el-config-provider>

</template>

<style>

</style>
<script lang="ts" setup>

</script>
